.footer {
  margin-bottom: 16px;
  display: flex;
  color: #333;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 8px;
}

.footerText {
  padding: 0;
  margin: 0 0 8px;
}

@media only screen and (max-width: 600px) {
  .footerText {
    font-size: 12px;
    margin: 0 0 4px;
  }
}
