.container {
  width: 70vw;
  text-align: center;
}

.dropZoneStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #eee;
  color: #999;
  flex: 1 1 0;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #bbb;
  border-style: dashed;
  outline: none;
  transition: border 0.24s ease-in-out 0s;
  cursor: pointer;
}

.thumbsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.thumb {
  display: inline-flex;
  border-radius: 4px;
  border: 2px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 160px;
  height: 240px;
  padding: 4px;
  justify-content: center;
}

.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.img {
  display: block;
  width: auto;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .container {
    width: 99vw;
  }
}
