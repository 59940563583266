.header {
  height: 72px;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.2);
  color: #666;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.title {
  flex-grow: 1;
  font-size: 1.6rem;
}

.img {
  height: 40px;
  cursor: pointer;
}

.red {
  color: #e33230;
}

.icons {
  display: flex;
  align-items: center;
  flex-grow: 0;
  padding-right: 20px;
}

@media only screen and (max-width: 600px) {
  .header {
    height: 48px;
    padding-left: 10px;
  }

  .title {
    font-size: 1.25rem;
  }

  .img {
    height: 26px;
  }
}
