html,
body,
#root {
  padding: 0;
  margin: 0;
}

#App {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 95vh;
}

.App-content {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
